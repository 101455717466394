import { Box, BoxProps } from "@mui/material";
import { CSSProperties, useMemo } from "react";

// x轴排列的Stack, 默认居中
interface XStackProps extends BoxProps {
    align?: "left" | "center" | "right";
    direction?: "up" | "center" | "down";
}
export function XStack(props: XStackProps) {
    const { align, direction, sx, ...pp } = props;
    const flexAlign = useMemo((): CSSProperties["justifyContent"] => {
        if (align !== undefined) {
            if (align === "left") {
                return "start";
            } else if (align === "center") {
                return "center";
            } else if (align === "right") {
                return "end";
            }
        }
        return undefined;
    }, [align]);

    // 默认为center
    const alignItems = useMemo((): CSSProperties["alignItems"] => {
        if (direction !== undefined) {
            if (direction === "up") {
                return "flex-start";
            } else if (direction === "center") {
                return "center";
            } else if (direction === "down") {
                return "flex-end";
            }
        }
        return "center";
    }, [direction]);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: alignItems,
                justifyContent: flexAlign,
                ...sx,
            }}
            {...pp}
        ></Box>
    );
}

interface YStackProps extends BoxProps {
    align?: "left" | "center" | "right"; // 默认左对齐
}
// y轴排列的Stack, 默认居中
export function YStack(props: YStackProps) {
    const { align, sx, ...pp } = props;
    const flexAlign = useMemo((): CSSProperties["alignItems"] => {
        if (align !== undefined) {
            if (align === "left") {
                return "start";
            } else if (align === "center") {
                return "center";
            } else if (align === "right") {
                return "end";
            }
        }
        return "left";
    }, [align]);
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: flexAlign,
                ...sx,
            }}
            {...pp}
        ></Box>
    );
}
