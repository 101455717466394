import {
    methodCreateExam,
    methodFinishSection,
    methodJudge,
    methodOpenExam,
    methodRestartExam,
    methodStartBlindReview,
} from "../../api/apiexam/api";
import { sleep } from "../../component/tools/time";
import { Book } from "../../model/book/Book";
import { TemplateParam } from "../../model/book/TemplateParam";
import { QuestionAction } from "../../model/question/Question";
import { actionSetBook } from "../book/reducer";
import { actionSetBlindReviewDialog } from "../dialog/reducer";
import { AppThunk } from "../hooks";
import { RootState } from "../ReduxStore";
import { actionSetDisplayMode, actionSetShowResult, DisplayMode } from "../root/reducer";
import { actionEnterSection, enterExam } from "./action_tools";
import {
    actionCleanExamRuntime,
    actionSetExam,
    actionSetExamStage,
    actionSetFromURL,
    actionSetPageIndex,
    ExamStage,
} from "./reducer";
import { selectExamID, selectFromURL, selectSection, selectSectionAction } from "./selector";

export const actionCreateExam = (
    id: string,
    navigate: any,
    fromURL: string,
    param?: TemplateParam,
    isSimulate?: boolean
): AppThunk => {
    return async (dispatch: any, getState: () => RootState) => {
        methodCreateExam(dispatch, {
            temp_id: id,
            param: param || new TemplateParam(),
            is_simulate: isSimulate ? true : false,
        }).then((rsp) => {
            dispatch(actionSetBook(rsp.book));
            enterExam(rsp.exam, dispatch, navigate, fromURL);
        });
    };
};

export const actionOpenExam = (
    examID: string,
    navigate: any,
    fromURL: string,
    afterSuccess?: () => void
): AppThunk => {
    return async (dispatch: any, getState: () => RootState) => {
        methodOpenExam(dispatch, { exam_id: examID })
            .then((rsp) => {
                dispatch(actionSetBook(rsp.book));
                enterExam(rsp.exam, dispatch, navigate, fromURL);
            })
            .then(() => {
                if (afterSuccess) {
                    afterSuccess();
                }
            });
    };
};

export const actionRestartExam = (book: Book, navigate: any, fromURL: string): AppThunk => {
    return async (dispatch: any, getState: () => RootState) => {
        methodRestartExam(dispatch, { book_id: book.id }).then((rsp) => {
            dispatch(actionSetBook(rsp.book));
            enterExam(rsp.exam, dispatch, navigate, fromURL);
        });
    };
};

export const actionQuitExam = (navigate: any): AppThunk => {
    return async (dispatch: any, getState: () => RootState) => {
        const state = getState();
        const fromURL = selectFromURL(state);
        if (fromURL.length > 0) {
            navigate(fromURL);
        } else {
            navigate("/");
        }
        await sleep(100); // 等待一下再清理runtime数据, 避免navigate还未生效时, ExamPage自动重新拉取exam数据
        dispatch(actionSetDisplayMode(DisplayMode.NoneDisplay));
        dispatch(actionCleanExamRuntime());
    };
};

export const actionQuitSection = (): AppThunk => {
    return (dispatch: any, getState: () => RootState) => {
        dispatch(actionSetExamStage(ExamStage.Entrance));
        dispatch(actionSetPageIndex(1));
    };
};

export const actionFinishSection = (): AppThunk => {
    return async (dispatch: any, getState: () => RootState) => {
        const state = getState();
        const estate = state.examRuntime;
        const action = selectSectionAction(state);
        const action2 = { ...action, finished: true };
        const section = selectSection(state);
        const questionActions = [] as QuestionAction[];
        for (let i = 0; i < section.questions.length; i++) {
            questionActions.push(section.questions[i].runtime.action);
        }

        methodFinishSection(dispatch, {
            exam_id: estate.examID,
            section_index: estate.sectionIndex,
            section_action: action2,
            question_actions: questionActions,
        })
            .then((rsp) => {
                dispatch(actionSetExam(rsp.exam));
                dispatch(actionSetExamStage(ExamStage.Entrance));
                return rsp.exam;
            })
            .then((exam) => {
                if (exam.result.wait_blind_review) {
                    dispatch(actionSetBlindReviewDialog(true));
                }
            });
    };
};

export const actionStartBlindReview = (): AppThunk => {
    return async (dispatch: any, getState: () => RootState) => {
        const state = getState();
        const examID = selectExamID(state);
        methodStartBlindReview(dispatch, { exam_id: examID }).then((rsp) => {
            dispatch(actionCleanExamRuntime());
            dispatch(actionSetExam(rsp.exam));
            dispatch(actionSetFromURL(selectFromURL(state)));
        });
    };
};

// export const actionSkipBlindReview = (): AppThunk => {
//     return async (dispatch: any, getState: () => RootState) => {
//         const state = getState();
//         const examID = selectExamID(state);
//         methodSkipBlindReview(dispatch, { exam_id: examID }).then((rsp) => {
//             dispatch(actionCleanExamRuntime());
//             dispatch(actionSetExam(rsp.exam));
//             dispatch(actionSetFromURL(selectFromURL(state)));
//         });
//     };
// };

export const actionJudgeExam = (skipBlindReview: boolean): AppThunk => {
    return async (dispatch: any, getState: () => RootState) => {
        const state = getState();
        const examID = selectExamID(state);
        methodJudge(dispatch, { exam_id: examID, skip_blind_review: skipBlindReview }).then(
            (rsp) => {
                dispatch(actionSetExam(rsp.exam));
                dispatch(actionEnterSection(0));
                dispatch(actionSetExamStage(ExamStage.Result));
                dispatch(actionSetShowResult(true));
            }
        );
    };
};
