import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, CircularProgress, Link, Stack } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { methodCheckPaid, methodGetTrade, methodInitPayVIP } from "../../../../api/apipay/api";
import { Product } from "../../../../model/pay/product";
import { Trade, TradeStatus } from "../../../../model/pay/trade";
import { useAppDispatch } from "../../../../store/hooks";
import { YStack } from "../../../basic/surfaces/box/xstack";
import { Colors } from "../../../theme/color";
import { sleep, useInterval } from "../../../tools/time";
import { RefreshPage } from "../../../tools/url";
import vipPayImg from "./asset/vip_pay.jpg";
import wechatPay from "./asset/we.png";

interface VIPPayProps {
    product: Product;
}
export function VIPPay(props: VIPPayProps) {
    const dispatch = useAppDispatch();

    const { product } = props;

    const [trade, setTrade] = useState(new Trade());
    const status = trade.status;

    const callInitPay = useCallback(async () => {
        return methodInitPayVIP(dispatch, {
            product_id: product.id,
        }).then((rsp) => {
            setTrade(rsp.trade);
        });
    }, [dispatch, product.id]);

    const onSuccess = () => {
        sleep(1000).then(() => {
            RefreshPage();
        });
    };
    const successMsg = "支付成功，即将自动刷新网页（若网页长时间没有刷新，您可以手动刷新）";

    // init pay
    useEffect(() => {
        if (trade.status !== TradeStatus.None) {
            return;
        }
        callInitPay();
    }, [dispatch, callInitPay, trade.status]);

    // auto update trade status
    useInterval(() => {
        if (trade.id.length === 0) {
            return;
        }
        if (!(trade.status === TradeStatus.None || trade.status === TradeStatus.WaitPay)) {
            return;
        }
        methodGetTrade(dispatch, {
            trade_id: trade.id,
        }).then((rsp) => {
            setTrade(rsp.trade);
            if (rsp.trade.status === TradeStatus.ApplySuccess) {
                if (onSuccess) {
                    onSuccess();
                }
            }
        });
    }, 5000);

    const makeContent = () => {
        if (status === TradeStatus.None) {
            return <InitPayDisplay />;
        }
        if (status === TradeStatus.WaitPay) {
            return <WaitPayDisplay trade={trade} />;
        }
        if (status === TradeStatus.PaySuccess) {
            return <SuccessDisplay msg="已收到支付，处理中请稍候..." />;
        }
        if (status === TradeStatus.ApplySuccess) {
            return <SuccessDisplay msg={successMsg} />;
        }
        return <ErrorDisplay msg="网络异常" />;
    };

    return <>{makeContent()}</>;
}

function InitPayDisplay() {
    return (
        <Stack sx={{ alignItems: "center", justifyContent: "center" }}>
            <p>内容加载中...</p>
        </Stack>
    );
}

interface WaitPayDisplayProps {
    trade: Trade;
}
function WaitPayDisplay(props: WaitPayDisplayProps) {
    const { trade } = props;

    const dispatch = useAppDispatch();

    const [checkPaid, _setCheckPaid] = useState(false);
    const setCheckPaid = (b: boolean) => {
        if (b) {
            methodCheckPaid(dispatch, { trade_id: trade.id });
        }
        _setCheckPaid(b);
    };

    return (
        <>
            {checkPaid ? (
                <CheckPaidDisplay />
            ) : (
                <Stack sx={{ alignItems: "center" }}>
                    <Box sx={{ position: "relative" }}>
                        <PayDisplay price={trade.price} setCheckPaid={setCheckPaid}>
                            <QRDisplay trade={trade} refreshing={false} />
                        </PayDisplay>
                    </Box>
                </Stack>
            )}
        </>
    );
}

function QRDisplay(props: { trade: Trade; refreshing: boolean }) {
    const { trade, refreshing } = props;
    const getKind = () => {
        if (refreshing) {
            return 0;
        }
        if (trade.qr_text.length > 0) {
            return 1;
        }
        if (trade.qr_img_url.length > 0) {
            return 2;
        }
    };

    const kind = getKind();
    return (
        <>
            {kind === 0 && (
                <Box
                    sx={{
                        width: "270px",
                        height: "270px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <CircularProgress />
                    <Box sx={{ mt: "30px" }}>支付二维码加载中，请稍后</Box>
                    <Box sx={{ fontSize: 10 }}>若长时间（30s）未显示，可关闭窗口重试</Box>
                </Box>
            )}
            {kind === 1 && (
                <Box sx={{ position: "relative", width: "270px", height: "270px" }}>
                    <QRCodeSVG size={270} value={trade.qr_text} />
                    {trade.is_debug && (
                        <Box
                            sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%,-50%)",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "70px",
                                width: "70px",
                                color: "rgb(0,0,255)",
                                fontWeight: 700,
                                fontSize: 30,
                                backgroundColor: "rgb(255,255,255)",
                                border: "solid 1px",
                                borderRadius: "5px",
                            }}
                        >
                            调试
                        </Box>
                    )}
                    {trade.is_wechat && (
                        <Box
                            sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%,-50%)",
                                backgroundColor: "rgb(255,255,255)",
                            }}
                        >
                            <img src={wechatPay} alt="" height="60px" />
                        </Box>
                    )}
                </Box>
            )}
            {kind === 2 && (
                <Box sx={{ position: "relative" }}>
                    <img src={trade.qr_img_url} alt="微信付款二维码" width="270px" height="270px" />
                </Box>
            )}
        </>
    );
}

function SuccessDisplay(props: { msg: string }) {
    const { msg } = props;
    return (
        <Stack sx={{ alignItems: "center", justifyContent: "center" }}>
            <Box
                sx={{
                    width: "300px",
                    height: "300px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <CheckCircleIcon sx={{ color: Colors["success"], fontSize: 60 }} />
            </Box>
            <p>{msg}</p>
        </Stack>
    );
}

function CheckPaidDisplay() {
    return (
        <Stack sx={{ alignItems: "center", justifyContent: "center" }}>
            <Box
                sx={{
                    width: "300px",
                    height: "300px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <CircularProgress size={70} />
            </Box>
            <Box sx={{ fontSize: 18 }}>订单确认中，请稍候...</Box>
        </Stack>
    );
}
function ErrorDisplay(props: { msg: string; hint?: string }) {
    const { msg, hint } = props;
    return (
        <Stack sx={{ alignItems: "center", justifyContent: "center" }}>
            <Box
                sx={{
                    width: "300px",
                    height: "300px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <CancelIcon sx={{ color: Colors["error"], fontSize: 60 }} />
            </Box>
            <p>{msg}</p>
            <p>{hint !== undefined ? hint : "请转微信客服人工处理（24小时内响应）：BBGRE01"}</p>
        </Stack>
    );
}

function PayDisplay(
    props: PropsWithChildren<{ price: number; setCheckPaid: (b: boolean) => void }>
) {
    const { children, price, setCheckPaid } = props;

    const getRelative = () => {
        return ["377px", "220px", vipPayImg, "底图", "310px"];
    };
    const [top, left, imgSrc, alt, priceTop] = getRelative();
    return (
        <Box sx={{ position: "relative" }}>
            {/* 背景 */}
            <img src={imgSrc} alt={alt} width="700px" />
            {/* 价格文字 */}
            {price > 0 && (
                <>
                    <Box
                        sx={{
                            position: "absolute",
                            top: priceTop,
                            left: "50%",
                            transform: "translateX(-50%)",
                        }}
                    >
                        <YStack align="center">
                            <>
                                <Box sx={{ mt: "-8px" }}>一经开通不退不换</Box>
                            </>
                            <Box sx={{ fontSize: 20, mt: "8px" }}>
                                总计&nbsp;
                                <b>{price}</b>
                                &nbsp;元，请使用<b>微信</b>支付
                            </Box>
                        </YStack>
                    </Box>
                </>
            )}
            {/* 支付二维码 */}
            <Box sx={{ position: "absolute", top: top, left: left, zIndex: 10 }}>{children}</Box>
            <Box
                sx={{
                    position: "absolute",
                    top: `calc(${top} + 290px)`,
                    left: left,
                    zIndex: 12,
                    fontSize: 13,
                    // color: Colors["bbgray"],
                    textAlign: "center",
                }}
            >
                <YStack align="center">
                    <Box>支付完成后请在本页面等待，网页会自动刷新</Box>
                    <Link sx={{ fontSize: 13, mt: "10px" }} onClick={() => setCheckPaid(true)}>
                        我已完成支付
                    </Link>
                </YStack>
            </Box>
        </Box>
    );
}
