import { Box, Button, Divider, TextField } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { NavigateFunction } from "react-router";
import { methodOpenExam } from "../../../api/apiexam/api";
import { methodEditSection } from "../../../api/apiquestion/api";
import { EditSectionKind, SectionEditExtraData } from "../../../model/section/edit";
import { SectionData, SectionKindItems } from "../../../model/section/section_data";
import { selectBook } from "../../../store/book/selector";
import { actionSetEditSectionDialog } from "../../../store/dialog/reducer";
import { selectEditSectionDialog } from "../../../store/dialog/selector";
import { actionQuitExam, actionRestartExam } from "../../../store/exam/action";
import { actionSetExam } from "../../../store/exam/reducer";
import { selectExam, selectFromURL } from "../../../store/exam/selector";
import { useAppDispatch } from "../../../store/hooks";
import { BBDialog } from "../../basic/data_displays/dialog/bb_dialog";
import { useConfirmDialog } from "../../basic/data_displays/dialog/confirm_dialog";
import { PureTextAutoSaveEditor } from "../../basic/data_displays/rich_text/auto_save/pure_text_auto_save_editor";
import { AlertError, AlertSuccess } from "../../basic/data_displays/snackbar/Snackbar";
import { useSelectEnumItem } from "../../basic/inputs/select/select_enum";
import { TitleDiv } from "../../basic/surfaces/box/title_div";
import { XStack } from "../../basic/surfaces/box/xstack";
import { sleep } from "../../tools/time";

export function EditSectionDialog() {
    const dispatch = useAppDispatch();
    const dialog = useSelector(selectEditSectionDialog);
    const open = dialog.open;
    const setOpen = (b: boolean) => {
        dispatch(
            actionSetEditSectionDialog({
                ...dialog,
                open: b,
                data: b ? dialog.data : new SectionData(),
            })
        );
    };
    return (
        <BBDialog
            open={open}
            setOpen={setOpen}
            content={
                <DialogContent data={dialog.data} navigate={dialog.navigate} setOpen={setOpen} />
            }
        />
    );
}

function DialogContent(props: {
    navigate: NavigateFunction;
    data: SectionData;
    setOpen: (b: boolean) => void;
}) {
    const dispatch = useAppDispatch();

    const { data, navigate, setOpen } = props;

    const [confirmDecreseQuestionCountDiv, openConfirm] = useConfirmDialog();

    const book = useSelector(selectBook);
    const fromURL = useSelector(selectFromURL);
    const exam = useSelector(selectExam);

    const remakeExam = async () => {
        dispatch(actionQuitExam(navigate));
        await sleep(100);
        dispatch(actionRestartExam(book, navigate, fromURL));
        setOpen(false);
        AlertSuccess("操作成功");
    };

    const refreshExam = () => {
        methodOpenExam(dispatch, { exam_id: exam.id }).then((rsp) => {
            dispatch(actionSetExam(rsp.exam));
        });
        setOpen(false);
        AlertSuccess("操作成功");
    };

    const handleUpdateQuestionCount = (count: number) => {
        let data2 = structuredClone(data);
        if (count < data.question_ids.length) {
            data2.question_ids = data.question_ids.slice(0, count);
        } else {
            data2.question_ids = data.question_ids.concat(
                new Array(count - data.question_ids.length).fill("")
            );
        }
        methodEditSection(dispatch, {
            edit_kind: EditSectionKind.QuestionIDs,
            data_id: data.id,
            data: data2,
            extra: { remove_overflowed_question_ids: false } as SectionEditExtraData,
        }).then(remakeExam);
    };

    const [kindDiv] = useSelectEnumItem({
        items: SectionKindItems,
        defaultValue: data.kind,
        onChange: (item) => {
            methodEditSection(dispatch, {
                edit_kind: EditSectionKind.SectionKind,
                data_id: data.id,
                data: { ...data, kind: item.value },
                extra: {} as SectionEditExtraData,
            }).then(remakeExam);
        },
    });

    return (
        <>
            {data.id.length > 0 && (
                <>
                    <h3>编辑Section</h3>
                    <TitleDiv title="ID">{data.id}</TitleDiv>
                    <TitleDiv title="编号">{data.locations_desp}</TitleDiv>
                    <TitleDiv title="类型">{kindDiv}</TitleDiv>
                    <TitleDiv title="题目数量">
                        <PureTextAutoSaveEditor
                            text={data.question_ids.length.toString()}
                            onSaveAsync={async (t: string) => {
                                const count = parseInt(t);
                                if (isNaN(count)) {
                                    AlertError("请输入数字");
                                    return false;
                                }
                                if (count < 0) {
                                    AlertError("题目数量不能小于0");
                                    return false;
                                }
                                // 确认缩容
                                if (count < data.question_ids.length) {
                                    const result = await openConfirm(
                                        `题目的数量将会减少，编号超过${count}的题将成为游离题（可被搜到但不在PT），确认？`
                                    )
                                        .then(() => {
                                            return true;
                                        })
                                        .catch(() => {
                                            return false;
                                        });
                                    if (!result) {
                                        return false;
                                    }
                                }
                                // 扩容
                                handleUpdateQuestionCount(count);
                                return true;
                            }}
                        />
                    </TitleDiv>

                    <Divider sx={{ mt: "20px" }} />
                    <h4>小工具</h4>
                    <BatchAddGroupID data={data} refreshExam={refreshExam} />
                </>
            )}
            {confirmDecreseQuestionCountDiv}
        </>
    );
}

function BatchAddGroupID(props: { data: SectionData; refreshExam: () => void }) {
    const { data, refreshExam } = props;
    const dispatch = useAppDispatch();
    const [startDiv, start] = useNumberInput();
    const [endDiv, end] = useNumberInput();

    return (
        <Box>
            <XStack>
                将第
                {startDiv}至{endDiv}
                题分为同一组（添加相同的群编号）
                <Button
                    onClick={() => {
                        if (isNaN(start) || isNaN(end)) {
                            AlertError("请输入数字");
                            return;
                        }
                        methodEditSection(dispatch, {
                            edit_kind: EditSectionKind.BatchNewGroupID,
                            data_id: data.id,
                            data: data,
                            extra: {
                                batch_new_group_start: start,
                                batch_new_group_end: end,
                            } as SectionEditExtraData,
                        }).then(refreshExam);
                    }}
                >
                    执行
                </Button>
            </XStack>
        </Box>
    );
}

function useNumberInput(): [any, number] {
    const [input, setInput] = useState("");
    return [
        <TextField
            placeholder="数字"
            size="small"
            sx={{ width: "60px" }}
            value={input}
            onChange={(e) => setInput(e.target.value)}
        />,
        parseInt(input),
    ];
}
