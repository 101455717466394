import { RootState } from "../ReduxStore";

export const _selectUserRuntime = (state: RootState) => state.userRuntime;

export const selectUserAuth = (state: RootState) => _selectUserRuntime(state).auth;
export const selectHasToken = (state: RootState) =>
    _selectUserRuntime(state).auth.user_id.length > 0;

export const selectLogin = (state: RootState) => _selectUserRuntime(state).login;

export const selectEditMode = (state: RootState) => _selectUserRuntime(state).editMode;
export const selectShowTranslate = (state: RootState) => _selectUserRuntime(state).showTranslate;

export const selectUser = (state: RootState) => _selectUserRuntime(state).data;
export const selectUserID = (state: RootState) => selectUser(state).id;
export const selectUserAuthority = (state: RootState) => selectUser(state).authority;
export const selectIsAdmin = (state: RootState) => selectUserAuthority(state).is_admin;
export const selectIsVIP = (state: RootState) => selectUserAuthority(state).is_vip;
export const selectCanReviewPost = (state: RootState) => selectUserAuthority(state).can_review_post;

export const selectVIPDialog = (state: RootState) => _selectUserRuntime(state).vipDialog;

export const selectUserInfoBank = (state: RootState) => _selectUserRuntime(state).infoBank;
