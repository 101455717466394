import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEnsureVIPProductsBank } from "../../../../store/root/ensure";
import { actionSetVIPDialog } from "../../../../store/user/reducer";
import { selectVIPDialog } from "../../../../store/user/selector";
import { SwitchContent } from "../../../basic/data_displays/switch/SwitchContent";
import { VIPPay } from "./vip_pay";

export function VIPDialog() {
    const dispatch = useDispatch();

    const open = useSelector(selectVIPDialog);
    const bank = useEnsureVIPProductsBank(open);

    const onClose = () => {
        dispatch(actionSetVIPDialog(false));
    };
    const onConfirm = () => {
        dispatch(actionSetVIPDialog(false));
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>开通会员</DialogTitle>
            <DialogContent>
                {bank.isBackendData ? (
                    <SwitchContent
                        titles={bank.products.map((p) => p.name)}
                        contents={bank.products.map((p) => (
                            <VIPPay product={p} />
                        ))}
                    />
                ) : (
                    <>加载中...</>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onConfirm} autoFocus>
                    关闭
                </Button>
            </DialogActions>
        </Dialog>
    );
}
