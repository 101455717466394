import { API, apiRequest } from "../api";
import {
    CheckPaidReq,
    CheckPaidRsp,
    GetTradeReq,
    GetTradeRsp,
    InitPayVIPReq,
    InitPayVIPRsp,
} from "./pay";
import { GetVIPProductsReq, GetVIPProductsRsp } from "./product";

export const apiInitPayVIP: API<InitPayVIPReq, InitPayVIPRsp> = {
    url: "/api/pay/init_pay_vip",
};

export function methodInitPayVIP(dispatch: any, req: InitPayVIPReq) {
    return apiRequest({ api: apiInitPayVIP, req, dispatch, backdrop: true });
}

export const apiGetTrade: API<GetTradeReq, GetTradeRsp> = {
    url: "/api/pay/get_trade",
};

export function methodGetTrade(dispatch: any, req: GetTradeReq) {
    return apiRequest({ api: apiGetTrade, req, dispatch, backdrop: true });
}

export const apiGetVIPProducts: API<GetVIPProductsReq, GetVIPProductsRsp> = {
    url: "/api/pay/get_vip_products",
};

export function methodGetVIPProducts(dispatch: any, req: GetVIPProductsReq) {
    return apiRequest({ api: apiGetVIPProducts, req, dispatch, backdrop: true });
}

export const apiCheckPaid: API<CheckPaidReq, CheckPaidRsp> = {
    url: "/api/pay/check_paid",
};

export function methodCheckPaid(dispatch: any, req: CheckPaidReq) {
    return apiRequest({ api: apiCheckPaid, req, dispatch, backdrop: true });
}
