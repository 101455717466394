import { TemplateInfo } from "../book/Template";
import { RateCount } from "../Count";
import { Section } from "../section/section_data";

export class Exam {
    id: string = "";
    full_name: string = "";
    user_id: string = "";
    template: TemplateInfo = new TemplateInfo();

    status: number = 0;
    cursor: Cursor = new Cursor();
    result: ExamResult = new ExamResult();
    action: ExamAction = new ExamAction();

    sections: Section[] = [new Section()];

    isBackend: boolean = false;
}

export class Cursor {
    section_index: number = 0;
    question_index: number = 0;
}

export class ExamResult {
    all_sections_finished: boolean = false;
    wait_blind_review: boolean = false;
    judged: boolean = false;
    correct: RateCount = new RateCount();
}

export class ExamAction {
    used_time_seconds: number = 0;
    avg_time_seconds: number = 0;
}

export enum ExamStatus {
    NotStart = 0,
    InExam = 1, // 普通考试进行中
    InBlindReview = 2, // Blind Review进行中
    Finished = 3,
    Close = 4,
}
