import { useEffect } from "react";
import { useSelector } from "react-redux";
import { methodGetVIPProducts } from "../../api/apipay/api";
import { useAppDispatch } from "../hooks";
import { actionSetVIPProducts, VIPProductsBank } from "./reducer";
import { selectVIPProductsBank } from "./selector";

export function useEnsureVIPProductsBank(enable: boolean): VIPProductsBank {
    const dispatch = useAppDispatch();
    const bank = useSelector(selectVIPProductsBank);
    useEffect(() => {
        if (!enable) {
            return;
        }
        if (bank.isBackendData) {
            return;
        }
        methodGetVIPProducts(dispatch, {}).then((rsp) => {
            dispatch(
                actionSetVIPProducts({
                    products: rsp.products,
                    isBackendData: true,
                } as VIPProductsBank)
            );
        });
    }, [dispatch, bank]);
    return bank;
}
