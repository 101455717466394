import { StandardAnswer } from "../Answer";
import { FullTags } from "../FullTags";
import { RichText } from "../RichText";

export class QuestionData {
    id: string = "";
    visibility: number = 0;
    difficulty: number = 0;
    passage: Passage = new Passage();
    group_passage: GroupPassage = new GroupPassage();
    inquiry: Inquiry = new Inquiry();
    options: Option[] = [new Option()];
    standard_answer: StandardAnswer = new StandardAnswer();
    group_id: string = "";
    analysis: Analysis = new Analysis();
    bookmarked: boolean = false;
    full_tags: FullTags = new FullTags();
    mark: Mark = new Mark();

    accessable: boolean = false;

    locations_desp: string = "";
    section_kind: number = 0;
    section_kind_str: string = "";
}

export class Passage {
    rich_text: RichText = new RichText();
    fingerprint: string = "";
    translate: string = "";
}

export class GroupPassage {
    id: string = "";
    frame_work: RichText = new RichText();
}

export class Inquiry {
    text_type: number = 0;
    rich_text: RichText = new RichText();
    translate: string = "";
}

export class Option {
    key: string = "";
    text: string = "";
    translate: string = "";
}

export class Analysis {
    en: ENAnalysis = new ENAnalysis();
    cn: CNAnalysis = new CNAnalysis();
}

export class ENAnalysis {
    text: RichText = new RichText();
    accessible: boolean = false;
}

export class CNAnalysis {
    text: RichText = new RichText();
    gpt_records: string[] = [];
    text_gpt: RichText = new RichText();
    text_before_polish: RichText = new RichText();
    text_gpt_polished: RichText = new RichText();
}

export class Mark {
    xi: boolean = false;
    doggie: boolean = false;
    chick: boolean = false;
}
