import { LogDebug } from "../../component/tools/log";
import { UpdateQuestionRsp } from "../../model/exam/ExamDataOld";
import { API, apiRequest } from "../api";
import {
    CreateReq,
    CreateRsp,
    FinishSectionReq,
    FinishSectionRsp,
    JudgeReq,
    JudgeRsp,
    OpenReq,
    OpenRsp,
    RestartReq,
    RestartRsp,
    StartBlindReviewReq,
    StartBlindReviewRsp,
    UpdateQuestionReq,
} from "./exam";

export const apiOpenExam: API<OpenReq, OpenRsp> = {
    url: "/api/exam/open",
};

export function methodOpenExam(dispatch: any, req: OpenReq) {
    return apiRequest({ api: apiOpenExam, req, dispatch, backdrop: true });
}

export const apiCreateExam: API<CreateReq, CreateRsp> = {
    url: "/api/exam/create",
};

export function methodCreateExam(dispatch: any, req: CreateReq) {
    return apiRequest({ api: apiCreateExam, req, dispatch });
}

export const apiRestartExam: API<RestartReq, RestartRsp> = {
    url: "/api/exam/restart",
};

export function methodRestartExam(dispatch: any, req: RestartReq) {
    return apiRequest({ api: apiRestartExam, req, dispatch });
}

export const apiUpdateQuestion: API<UpdateQuestionReq, UpdateQuestionRsp> = {
    url: "/api/exam/update_question",
};

export function methodUpdateQuestion(dispatch: any, req: UpdateQuestionReq) {
    LogDebug("methodUpdateQuestion", req);
    return apiRequest({ api: apiUpdateQuestion, req, dispatch });
}

export const apiFinishSection: API<FinishSectionReq, FinishSectionRsp> = {
    url: "/api/exam/finish_section",
};

export function methodFinishSection(dispatch: any, req: FinishSectionReq) {
    return apiRequest({ api: apiFinishSection, req, dispatch });
}

export const apiJudge: API<JudgeReq, JudgeRsp> = {
    url: "/api/exam/judge",
};

export function methodJudge(dispatch: any, req: JudgeReq) {
    return apiRequest({ api: apiJudge, req, dispatch, backdrop: true });
}

export const apiStartBlindReview: API<StartBlindReviewReq, StartBlindReviewRsp> = {
    url: "/api/exam/start_blind_review",
};

export function methodStartBlindReview(dispatch: any, req: StartBlindReviewReq) {
    return apiRequest({ api: apiStartBlindReview, req, dispatch, backdrop: true });
}

// export const apiSkipBlindReview: API<SkipBlindReviewReq, SkipBlindReviewRsp> = {
//     url: "/api/exam/skip_blind_review",
// };

// export function methodSkipBlindReview(dispatch: any, req: SkipBlindReviewReq) {
//     return apiRequest({ api: apiSkipBlindReview, req, dispatch, backdrop: true });
// }
