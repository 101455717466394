import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PageSource } from "../../model/PageSource";
import { MaterialGroup } from "../../model/ResourceData";
import { Product } from "../../model/pay/product";
import { BackendTagBank, Tag, TagBank } from "../../model/question/tag/Tag";
import { bookRuntimeSlice, isBookAction } from "../book/reducer";
import { dialogRuntimeSlice, isDialogAction } from "../dialog/reducer";
import { examRuntimeSlice, isExamAction } from "../exam/reducer";
import { isPostAction, postRuntimeSlice } from "../post/reducer";
import { isSearchAction, searchRuntimeSlice } from "../search/reducer";
import { isUserAction, userRuntimeSlice } from "../user/reducer";

export interface BookGroupsData {
    books: MaterialGroup[];
    isBackendData: boolean;
}

export interface WIPData {
    remainTime: number;
    isUrge: boolean;
}

export enum DisplayMode {
    NoneDisplay = 0,
    ExamDispaly = 1,
    SearchDispaly = 2,
}

export interface VIPProductsBank {
    products: Product[];
    isBackendData: boolean;
}

const rootSlice = createSlice({
    name: "root",
    initialState: {
        searchRuntime: searchRuntimeSlice.getInitialState(),
        userRuntime: userRuntimeSlice.getInitialState(),
        examRuntime: examRuntimeSlice.getInitialState(),
        bookRuntime: bookRuntimeSlice.getInitialState(),
        dialogRuntime: dialogRuntimeSlice.getInitialState(),
        postRuntime: postRuntimeSlice.getInitialState(),

        tagBank: new TagBank(),
        bookGroups: {
            books: [] as MaterialGroup[],
            isBackendData: false,
        },
        backdropCount: 0,
        wip: {
            remainTime: 0,
            isUrge: false,
        } as WIPData,
        displayMode: DisplayMode.NoneDisplay,
        showResult: false,
        pageSource: PageSource.None,
        vipProductsBank: { products: [], isBackendData: false } as VIPProductsBank,
    },
    reducers: {
        actionSetDisplayMode: (state, action: PayloadAction<DisplayMode>) => {
            state.displayMode = action.payload;
        },
        actionSetBooks: (state, action: PayloadAction<MaterialGroup[]>) => {
            state.bookGroups.books = action.payload;
            state.bookGroups.isBackendData = true;
        },
        actionAddBackdrop: (state) => {
            state.backdropCount++;
        },
        actionDecBackdrop: (state) => {
            state.backdropCount--;
        },
        actionOpenWIP: (state) => {
            state.wip.remainTime = 10000;
            state.wip.isUrge = false;
        },
        actionUrgeWIP: (state) => {
            state.wip.remainTime = 10000;
            state.wip.isUrge = true;
        },
        actionCloseWIP: (state) => {
            state.wip.remainTime = 0;
            state.wip.isUrge = false;
        },
        actionWIPTimePass: (state, action: PayloadAction<number>) => {
            state.wip.remainTime -= action.payload;
        },
        actionSetShowResult: (state, action: PayloadAction<boolean>) => {
            state.showResult = action.payload;
        },
        actionSetPageSource: (state, action: PayloadAction<PageSource>) => {
            state.pageSource = action.payload;
        },
        actionSetTagBank: (state, action: PayloadAction<BackendTagBank>) => {
            let bank = new TagBank();
            bank.is_backend_data = true;
            bank.tagMap = new Map();
            action.payload.tags.forEach((t) => bank.tagMap.set(t.id, t));
            state.tagBank = bank;
        },
        actionUpdateTags: (state, action: PayloadAction<Tag[]>) => {
            const tags = action.payload;
            for (let i = 0; i < tags.length; i++) {
                state.tagBank.tagMap.set(tags[i].id, tags[i]);
            }
        },
        actionUpdateTag: (state, action: PayloadAction<Tag>) => {
            const tag = action.payload;
            state.tagBank.tagMap.set(tag.id, tag);
        },
        actionRemoveTag: (state, action: PayloadAction<string>) => {
            state.tagBank.tagMap.delete(action.payload);
        },
        actionSetVIPProducts: (state, action: PayloadAction<VIPProductsBank>) => {
            state.vipProductsBank = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(isSearchAction, (state, action) => {
                searchRuntimeSlice.reducer(state.searchRuntime, action);
            })
            .addMatcher(isUserAction, (state, action) => {
                userRuntimeSlice.reducer(state.userRuntime, action);
            })
            .addMatcher(isExamAction, (state, action) => {
                examRuntimeSlice.reducer(state.examRuntime, action);
            })
            .addMatcher(isBookAction, (state, action) => {
                bookRuntimeSlice.reducer(state.bookRuntime, action);
            })
            .addMatcher(isDialogAction, (state, action) => {
                dialogRuntimeSlice.reducer(state.dialogRuntime, action);
            })
            .addMatcher(isPostAction, (state, action) => {
                postRuntimeSlice.reducer(state.postRuntime, action);
            });
    },
});

export const {
    actionSetBooks,
    actionCloseWIP,
    actionOpenWIP,
    actionUrgeWIP,
    actionWIPTimePass,
    actionAddBackdrop,
    actionDecBackdrop,
    actionSetDisplayMode,
    actionSetShowResult,
    actionSetPageSource,
    actionRemoveTag,
    actionSetTagBank,
    actionUpdateTag,
    actionUpdateTags,
    actionSetVIPProducts,
} = rootSlice.actions;

export default rootSlice.reducer;
