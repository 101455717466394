import { TemplateParam } from "./TemplateParam";

export class BookTemplate {
    id: string = "";
    index: number = 0;
    order: number = 0;
    kind: number = 0;
    tags: Map<string, string> = new Map<string, string>();
    hide: boolean = false;
    param: TemplateParam = new TemplateParam();
}

export class TemplateInfo {
    id: string = "";
    kind: number = 0;
    enable_blind_review: boolean = false;
}

export enum BookKind {
    None = 0,
    PT = 1,
    Practice = 2,
    Simulate = 3,
    Exercise = 4,
}

export enum BookStatus {
    NotOpen = 0,
    InExam = 1,
    WaitBlindReview = 2,
    InBlindReview = 3,
    Finish = 4,
}

export enum PracticeKind {
    None = 0,
    Collect = 1,
    Wrongs = 2,
    Note = 3,
}

export enum TagName {
    TempKind = "temp_kind",
    PTIndex = "pt_idx",
    PTVersion = "pt_ver",
    PracticeKind = "prac_kind",
    SectionKind = "sec_kind",
    SectionIndex = "sec_idx",
}
