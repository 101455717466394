export class Trade {
    id: string = "";
    status: number = 0;
    price: number = 0;
    qr_img_url: string = "";
    qr_text: string = "";
    is_debug: boolean = false;
    is_wechat: boolean = false;
}

export enum TradeStatus {
    None = 0,
    Init = 1,
    WaitPay = 2,
    PaySuccess = 3,
    PayFail = 4,
    PayCancel = 5,
    PayTimeout = 6,
    ApplySuccess = 7,
    ApplyFail = 8,
    Refund = 9,
}
