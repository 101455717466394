import { LogDebug } from "../../component/tools/log";
import { Exam, ExamStatus } from "../../model/exam/Exam";
import { actionSetBookIDOrigin } from "../book/reducer";
import { AppThunk } from "../hooks";
import { RootState } from "../ReduxStore";
import { actionSetDisplayMode, actionSetShowResult, DisplayMode } from "../root/reducer";
import {
    actionCleanExamRuntime,
    actionSetExam,
    actionSetExamStage,
    actionSetFromURL,
    actionSetPageIndex,
    actionSetPaused,
    actionSetSectionIndex,
    ExamStage,
} from "./reducer";

export function enterExam(e: Exam, dispatch: any, navigate: any, fromURL: string) {
    prepareForOpenExam(e, dispatch, fromURL);
    navigate(`/exam/${e.id}`);
}

export function prepareForOpenExam(e: Exam, dispatch: any, fromURL: string) {
    dispatch(actionSetDisplayMode(DisplayMode.ExamDispaly));
    dispatch(actionCleanExamRuntime());
    dispatch(actionSetExam(e));
    dispatch(actionSetFromURL(fromURL || ""));
    if (e.cursor.section_index === 0 && e.cursor.question_index === 0) {
        dispatch(actionEnterSection(0));
    }
    dispatch(actionSetBookIDOrigin(e.template.id));
    if (e.status === ExamStatus.Finished) {
        dispatch(actionSetShowResult(true));
        dispatch(actionSetExamStage(ExamStage.Result));
    } else {
        dispatch(actionSetShowResult(false));
        dispatch(actionSetExamStage(ExamStage.Entrance));
    }
}

// 进入指定section, 并且设置pageIndex为第一个可访问题
export const actionEnterSection = (sectionIndex: number): AppThunk => {
    return async (dispatch: any, getState: () => RootState) => {
        dispatch(actionSetPageIndex(1));
        dispatch(actionSetSectionIndex(sectionIndex));
        dispatch(actionSetExamStage(ExamStage.Intro));
        dispatch(actionSetPaused(false));
        const state = getState();
        const section = state.examRuntime.exam.sections[sectionIndex];
        if (!section.action.finished) {
            const maxQuestion = section.questions.length;
            LogDebug("cursor", maxQuestion, section.action.cursor);
            if (section.action.cursor > 0 && section.action.cursor < maxQuestion) {
                dispatch(actionSetPageIndex(section.action.cursor + 1));
                dispatch(actionSetExamStage(ExamStage.InExam));
            } else {
                // 跳到第一个可访问的题
                for (let i = 0; i < maxQuestion; i++) {
                    if (section.questions[i].data.accessable) {
                        dispatch(actionSetPageIndex(i + 1));
                        break;
                    }
                }
            }
        }
    };
};
