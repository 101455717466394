import { Craft } from "../craft/user_craft";
import { Answer } from "./Answer";
import { QuestionData } from "./data/question_data";

export class Question {
    order: number = 0;
    data_id: string = "";
    data: QuestionData = new QuestionData();
    runtime: QuestionRuntime = new QuestionRuntime();
    craft: Craft = new Craft();
}

export class QuestionRuntime {
    id: string = "";
    action: QuestionAction = new QuestionAction();
    result: QuestionResult = new QuestionResult();
    blind_suggest: boolean = false;
}

// export class QuestionAction {
//     normal: QuestionActionBase = new QuestionActionBase();
//     blind: QuestionActionBase = new QuestionActionBase();
//     current: QuestionActionBase = new QuestionActionBase();
// }

export class QuestionAction {
    answer: Answer = new Answer();
    hidden_options: boolean[] = [];
    flagged: boolean = false;
    used_time_seconds: number = 0;
}

export class QuestionResult {
    correct: boolean = false;
}
