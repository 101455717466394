export class RichText {
    kind: number = 0;
    text: string = "";
}

export enum RichTextKind {
    HTML = 1,
    PureText = 2,
    Markdown = 3,
}
