import { TextField } from "@mui/material";
import { useEffect, useState } from "react";

interface PureTextAutoSaveEditorProps {
    text: string;
    onSave?: (text: string) => void;
    onSaveAsync?: (t: string) => Promise<boolean>;
    multiline?: boolean;
    fullwidth?: boolean;
    placeholder?: string;
}
// 文本框编辑, 失去焦点时触发自动保存
export function PureTextAutoSaveEditor(props: PureTextAutoSaveEditorProps) {
    const { text } = props;

    const [draft, setDraft] = useState("");

    useEffect(() => {
        setDraft(text);
    }, [text]);

    return <PureTextDraftAutoSaveEditor {...props} draft={draft} setDraft={setDraft} />;
}

// 同PureTextAutoSaveEditor, 需手动传入draft,setDraft接口
interface PureTextDraftAutoSaveEditorProps extends PureTextAutoSaveEditorProps {
    draft: string;
    setDraft: (t: string) => void;
}
export function PureTextDraftAutoSaveEditor(props: PureTextDraftAutoSaveEditorProps) {
    const { text, onSave, onSaveAsync, multiline, fullwidth, placeholder, draft, setDraft } = props;

    return (
        <TextField
            placeholder={placeholder}
            value={draft}
            onChange={(e) => setDraft(e.target.value)}
            onBlur={async () => {
                if (draft !== text) {
                    if (onSave !== undefined) {
                        onSave(draft);
                    } else if (onSaveAsync !== undefined) {
                        const resolve = await onSaveAsync(draft);
                        if (!resolve) {
                            setDraft(text);
                        }
                    }
                }
            }}
            multiline={multiline}
            minRows={multiline ? 3 : undefined}
            fullWidth={fullwidth}
        />
    );
}
