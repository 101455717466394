export class User {
    id: string = "";
    name: string = "";
    authority = new Authority();
}

export class UserInfo {
    id: string = "";
    name: string = "";
    label: string = "";
}

export class UserInfoBank {
    infos: UserInfo[] = [];
    isBackend: boolean = false;
}

export class Authority {
    role_name: string = "";
    is_admin: boolean = false;
    can_review_post: boolean = false;
    is_vip: boolean = false;
}
