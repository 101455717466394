import { Tag, TagBank } from "../../model/question/tag/Tag";
import { RootState } from "../ReduxStore";

export const selectBookGroups = (state: RootState) => state.bookGroups;

export const selectBackdropCount = (state: RootState) => state.backdropCount;

export const selectWIP = (state: RootState) => state.wip;

export const selectDisplayMode = (state: RootState) => state.displayMode;

export const selectShowResult = (state: RootState) => state.showResult;

export const selectPageSource = (state: RootState) => state.pageSource;

export const selectTagBank = (state: RootState) => state.tagBank;

export const getTagsFromBank = (tagIDs: string[], bank: TagBank): Tag[] => {
    return tagIDs.map((id) => bank.tagMap.get(id)).filter((t) => t) as Tag[];
};

export const selectVIPProductsBank = (state: RootState) => state.vipProductsBank;
