import { EditSectionDialog } from "../section/edit_dialog";
import { VIPDialog } from "../user/buyvip/buy_dialog";
import PermissionMsgDialog from "./PermissionDialog";

export function Dialogs() {
    return (
        <>
            <PermissionMsgDialog />
            <VIPDialog />
            <EditSectionDialog />
        </>
    );
}
